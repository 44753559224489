


























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { MerchantAPI } from '@/api';
import Application from '@/models/application';
import isIp from 'is-ip';
import isCidr from 'is-cidr';
import { ErrorAPI } from '@/api';
import User from '../../models/user';

@Component
export default class ApplicationForm extends Vue {
  private app: any = false;
  private errors = new ErrorAPI();
  private isLoading: boolean = false;
  private isCreatedModalActive: boolean = false;
  private serverGenerated: boolean = true;
  private exists: boolean = false;

  private user(): User | null {
    return this.$store.getters['auth/user'];
  }

  private valueRegex(event: any, regex: string) {
    event = event ?? window.event;
    const re = new RegExp(regex);
    const clipboardData = event.clipboardData || window.clipboardData;
    let str: string;

    if (clipboardData !== undefined) {
      str = clipboardData.getData('Text');
    } else {
      str = String.fromCharCode(event.which ?? event.keyCode);
    }

    if (!re.test(str)) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  private onSubmit() {
    return this.exists ? this.onUpdate() : this.onCreate();
  }

  private onCreate() {
    this.errors.clear();

    if (!this.validate()) {
      return false;
    }
    this.isLoading = true;

    MerchantAPI.applicationCreate(this.app)
      .then(({public_key, private_key}: {app: any, public_key: string, private_key: string}) => {
      if (this.serverGenerated) {
        this.app.privateKey = private_key;
        this.app.publicKey = public_key;
        this.isCreatedModalActive = true;
      } else {
        this.toApplications();
      }
    }).catch((err: any) => {
      if (err.response !== undefined) {
        switch(err.response.status) {
          case 500:
            this.$buefy.toast.open({
              message: err.response?.data.error || err.toString(),
              position: 'is-bottom',
              type: 'is-danger',
            });
            break;
          default:
            this.errors.put(err.response.data ?? new Map());
        }
      }
    }).finally(() => { this.isLoading = false; });
  }

  public userPayout(): boolean {
    const user = this.user();

    return user !== null && user.payout;
  }

  private onUpdate() {
    this.errors.clear();
    if (!this.validate()) {
      return;
    }
    this.isLoading = true;

    MerchantAPI.applicationUpdate(this.app)
      .then(() => {
        this.$buefy.toast.open({
          message: this.$t('AppUpdatedMsg').toString(),
          position: 'is-bottom',
          type: 'is-success',
        });
      })
      .catch((err: any) => {
        if (err.response !== undefined) {
          switch(err.response.status) {
            case 500:
              this.$buefy.toast.open({
                message: err.response?.data.error || err.toString(),
                position: 'is-bottom',
                type: 'is-danger',
              });
              break;
            default:
              this.errors.put(err.response.data ?? new Map());
          }
        }
      })
      .finally(() => { this.isLoading = false; });
  }

  private toApplications(): void {
    this.$router.push({name: 'Applications'});
  }

  private validate(): boolean {
    let isSuccess = true;
    this.errors.clear();

    if (!ApplicationForm.isURL(this.app.hostname)) {
      this.errors.push('hostname', [this.$t('IsNotValidUrl').toString()]);
      isSuccess = false;
    }

    if (!ApplicationForm.isURL(this.app.postURL)) {
      this.errors.push('postURL', [this.$t('IsNotValidUrl').toString()]);
      isSuccess = false;
    }

    if (!ApplicationForm.isURL(this.app.backURL)) {
      this.errors.push('backURL', [this.$t('IsNotValidUrl').toString()]);
      isSuccess = false;
    }

    if (!ApplicationForm.isURL(this.app.successURL)) {
      this.errors.push('successURL', [this.$t('IsNotValidUrl').toString()]);
      isSuccess = false;
    }

    if (!ApplicationForm.isURL(this.app.failedURL)) {
      this.errors.push('failedURL', [this.$t('IsNotValidUrl').toString()]);
      isSuccess = false;
    }

    if (this.app.allowedNetworks === null || this.app.allowedNetworks === '') {
      this.errors.push('allowedNetworks', [this.$t('EmptyNetworks').toString()]);
      isSuccess = false;
    } else {
      const networks = this.app.allowedNetworks.trim().split(',');

      for (const network of networks) {
        if (!ApplicationForm.isValidNetwork(network)) {
          this.errors.push('allowedNetworks', [`${network} - ${this.$t('InvalidDataFormat')}. ${this.$t('UseIPMsg')}`]);
          isSuccess = false;
        }
      }
    }

    return isSuccess;
  }

  private static isValidNetwork(ip: string): boolean {
    return ip === '*' || isIp(ip) || isCidr(ip) !== 0;
  }

  private static isURL(url: string): boolean {
    return (
        new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
    ).test(url);
  }

  private mounted() {
    const appKey = this.$route.params.appKey;
    if (appKey !== undefined) {
      this.isLoading = true;
      this.exists = true;
      MerchantAPI.applicationFind(appKey)
          .then((app: Application) => {
            this.app = new Application(app);
          })
          .catch((err: any) => {
            this.$buefy.toast.open({
              message: err.response?.data.error || err.toString(),
              position: 'is-bottom',
              type: 'is-danger',
            });
          })
          .finally(() => { this.isLoading = false; });
    } else {
      this.app = new Application({});
    }
  }

}
